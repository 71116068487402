import React from 'react';
import cls from 'classnames';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import classes from './Divider.scss';

export interface IDividerProps extends TPAComponentProps {
  bw?: boolean;
  inset?: boolean;
  orientation?: 'horizontal' | 'vertical';
}

export function Divider(props: IDividerProps) {
  const { inset, className, bw, orientation = 'horizontal', ...rest } = props;
  return (
    <hr
      data-bw={bw}
      data-inset={inset}
      data-orientation={orientation}
      className={cls(classes.root, className, {
        [classes.bw]: bw,
        [classes.inset]: inset,
        [classes.vertical]: orientation === 'vertical',
        [classes.horizontal]: orientation === 'horizontal',
      })}
      {...rest}
    />
  );
}

Divider.displayName = 'wui/Divider';
