import React from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { EGroupPartition } from '@wix/social-groups-serverless/dist/groups/types';
import {
  selectCanCreateGroup,
  selectCanCreateGroupRequests,
} from 'store/selectors';
import type { IApplicationDialog } from 'store/application/types';
import { useController } from 'common/context/controller';

import { EmptyState } from 'wui/EmptyState';
import { Button } from 'wui/Button';

interface IGroupListFilters {
  title?: string;
}

interface IProps extends React.ComponentProps<typeof EmptyState> {
  partition: EGroupPartition;
  filters?: IGroupListFilters;

  onReset?(): void;
}

export function EmptyGroupList(props: IProps) {
  const { filters, partition, onReset, ...rest } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const canCreateGroup = useSelector(selectCanCreateGroup);
  const canCreateGroupRequests = useSelector(selectCanCreateGroupRequests);

  const { application$ } = useController();

  const showCreateGroupButton = canCreateGroup || canCreateGroupRequests;

  if (filters?.title) {
    return (
      <EmptyState
        variant="page-borderless"
        data-type="search-empty"
        title={t('groups-web.search.no-results.title')}
        subtitle={t('groups-web.search.no-results.text')}
        action={
          onReset ? (
            <Button onClick={onReset}>
              {t('groups-web.search.no-results.button.label')}
            </Button>
          ) : undefined
        }
        {...rest}
      />
    );
  }

  switch (partition) {
    case EGroupPartition.ALL:
      return (
        <EmptyState
          data-type="partition-empty"
          title={t('groups-web.empty-state.header')}
          subtitle={t('groups-web.empty-state.text')}
          action={
            showCreateGroupButton ? (
              <Button onClick={handleOpenDialog('createGroup')}>
                {t('groups-web.btn.create-new')}
              </Button>
            ) : undefined
          }
          {...rest}
        />
      );

    case EGroupPartition.JOINED:
      return (
        <EmptyState
          data-type="partition-empty"
          title={t('groups-web.joined.empty-state.title')}
          subtitle={t('groups-web.joined.empty-state.subtitle')}
          {...rest}
        />
      );

    case EGroupPartition.SUGGESTED:
      return (
        <EmptyState
          data-type="partition-empty"
          title={t('groups-web.suggested.empty-state.title')}
          subtitle={t('groups-web.suggested.empty-state.subtitle')}
          {...rest}
        />
      );
  }

  function handleOpenDialog(dialog: IApplicationDialog) {
    return function () {
      application$.showDialog({ dialog });
    };
  }
}

EmptyGroupList.displayName = 'EmptyGroupList';
