import {
  createStylesParam,
  StyleParamType,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings';
import { EAlignment, EImageCrop, EImageLayout } from 'settings/constants';

export const groupsListWidgetStylesParams = {
  /**
   * Design styles
   */
  imageCrop: createStylesParam('imageCrop', {
    type: StyleParamType.Number,
    getDefaultValue: () => EImageCrop.Crop,
  }),
  imageLayout: createStylesParam('imageLayout', {
    type: StyleParamType.Number,
    getDefaultValue: () => EImageLayout.LTR,
  }),
  itemTextAlignment: createStylesParam('itemTextAlignment', {
    type: StyleParamType.Number,
    getDefaultValue: () => EAlignment.Left,
  }),
  showActivity: createStylesParam('showActivity', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showGroupButton: createStylesParam('showGroupButton', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showListTitle: createStylesParam('showListTitle', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  listWidgetTitleColor: createStylesParam('listWidgetTitleColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  listWidgetTitleFont: createStylesParam('listWidgetTitleFont', {
    type: StyleParamType.Font,
    getDefaultValue: (params) =>
      wixFontParam('Title', {
        size: params.isMobile ? 16 : 32,
        htmlTag: 'h1',
      })(params),
  }),
  listWidgetTitleAlignment: createStylesParam('listWidgetTitleAlignment', {
    type: StyleParamType.Number,
    getDefaultValue: () => EAlignment.Left,
  }),
};
