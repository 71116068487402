import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import {
  PrivacyStatus,
  Type as AccessRestrictionType,
} from '@wix/ambassador-social-groups-v2-group/types';

import type { IGroup } from 'store/groups/types';
import type { TPAComponentProps } from 'wui/types';

interface IProps extends React.HTMLAttributes<HTMLSpanElement> {
  group: IGroup;
}

export function GroupPrivacyLabel(props: IProps & TPAComponentProps) {
  const { group, ...rest } = props;

  const { t } = useTranslation();

  if (group.accessRestriction?.type === AccessRestrictionType.PAID_PLANS) {
    return (
      <span
        {...rest}
        data-privacy={group.privacyStatus}
        data-restriction={AccessRestrictionType.PAID_PLANS}
      >
        {t('groups-web.types.paid-plans.name')}
      </span>
    );
  }

  switch (group.privacyStatus) {
    case PrivacyStatus.PUBLIC:
      return (
        <span {...rest} data-privacy={group.privacyStatus}>
          {t('groups-web.types.public.name')}
        </span>
      );

    case PrivacyStatus.PRIVATE:
      return (
        <span {...rest} data-privacy={group.privacyStatus}>
          {t('groups-web.types.private.name')}
        </span>
      );

    case PrivacyStatus.SECRET:
      return (
        <span {...rest} data-privacy={group.privacyStatus}>
          {t('groups-web.types.secret.name')}
        </span>
      );
  }

  return null;
}

GroupPrivacyLabel.displayName = 'GroupPrivacyLabel';
