import { createSettingsParam, SettingsParamType } from '@wix/tpa-settings';

import groupsSettingsParams from 'Groups/settingsParams';

export const WIDGET_NUM_GROUPS = 3;
export const WIDGET_MAX_NUM_GROUPS = 9;

export enum GroupsSelectorType {
  ALL = 'All',
  CUSTOM = 'Custom',
}

export const groupsListWidgetSettingsParams = {
  /**
   * Widget Layout settings
   */
  sortBy: groupsSettingsParams.sortBy,
  groupListLayout: groupsSettingsParams.groupListLayout,
  numberGroups: createSettingsParam('numberGroups', {
    getDefaultValue: () => WIDGET_NUM_GROUPS,
    type: SettingsParamType.Number,
  }),
  groupsSelectorType: createSettingsParam('groupsSelectorType', {
    type: SettingsParamType.String,
    getDefaultValue: () => GroupsSelectorType.ALL,
  }),
  groupIdsToShow: createSettingsParam('groupIdsToShow', {
    getDefaultValue: () => '',
    type: SettingsParamType.String,
  }),
  groupListTitle: createSettingsParam('groupListTitle', {
    getDefaultValue: ({ t }) => t('groups-web.settings.TextTab.groups.default'),
    type: SettingsParamType.String,
  }),
};
