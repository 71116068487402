import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import cls from 'classnames';

import classes from './GroupInfo.scss';

type MemberCountLabelProps = {
  memberLabel: {
    label: string;
    isCustom: boolean;
  };
  membersCount: number;
  dataHook?: string;
  withSeparator?: boolean;
};

export const MemberCountLabel = ({
  memberLabel,
  membersCount,
  dataHook,
  withSeparator,
}: MemberCountLabelProps) => {
  const { t } = useTranslation();

  return (
    <>
      {withSeparator && (
        <span className={cls(classes.separator, classes.memberCount)}>
          &middot;
        </span>
      )}
      <span data-hook={dataHook} className={classes.memberCount}>
        {memberLabel.isCustom
          ? `${membersCount} ${memberLabel.label}`
          : t(memberLabel.label, { formattedCount: membersCount })}
      </span>
    </>
  );
};

MemberCountLabel.displayName = 'MemberCountLabel';
