import { vars } from '../helpers';
import * as convertors from '../convertors';

export const getGroupsListWidgetCssVars = vars(
  ({ styleParams: { numbers, booleans } }) => {
    return {
      'item-text-justify-content': convertors.justifyContent(
        numbers.itemTextAlignment,
      ),
      'item-text-align-items': convertors.alignItems(numbers.itemTextAlignment),
      'group-activity-display': convertors.display(booleans.showActivity),
      'group-type-display': convertors.display(booleans.showGroupType),
      'member-count-display': convertors.display(booleans.showMemberCount),
      'group-actions-display': convertors.display(booleans.showGroupButton),
      'list-widget-title-display': convertors.display(booleans.showListTitle),
      'list-widget-title-alignment': convertors.textAlign(
        numbers.listWidgetTitleAlignment,
      ),
    };
  },
);
