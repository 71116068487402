import React from 'react';
import cls from 'classnames';

import { Box } from 'wui/Box';

import { EImageRatio } from 'settings/constants';

import classes from './AspectRatio.scss';

interface AspectRatioProps extends React.ComponentProps<typeof Box> {
  aspectRatio: EImageRatio;
}

export function AspectRatio({
  className,
  children,
  aspectRatio,
  ...rest
}: AspectRatioProps) {
  return (
    <Box
      data-aspect-ratio={aspectRatio}
      className={cls(classes.root, className, {
        [classes.cinema]: aspectRatio === EImageRatio.Rectangle,
        [classes.square]: aspectRatio === EImageRatio.Square,
      })}
      {...rest}
    >
      <div className={classes.child}>{children}</div>
    </Box>
  );
}

AspectRatio.displayName = 'wui/AspectRatio';
