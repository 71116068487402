import React from 'react';
import cls from 'classnames';
import {
  TextButton as TextButtonTPA,
  TextButtonPriority,
  TextButtonProps,
  HoverStyles,
} from 'wix-ui-tpa/cssVars';

import {
  OverridableComponent,
  OverridableComponentProps,
} from '../OverridableComponent';
import { Spinner } from '../Spinner';

import classes from './TextButton.scss';

interface IProps extends Omit<TextButtonProps, 'as' | 'priority'> {
  // wired to user settings
  wired?: boolean;
  noWrap?: boolean;
  loading?: boolean;
  variant?: 'primary' | 'secondary' | 'link';
}

// eslint-disable-next-line react/display-name
export const TextButton = React.forwardRef(
  (props: OverridableComponentProps<IProps, typeof TextButtonTPA>, ref) => {
    const {
      variant = 'primary',
      loading,
      noWrap,
      wired = false,
      className,
      children,
      ...rest
    } = props;

    return (
      <TextButtonTPA
        ref={ref}
        priority={variant as TextButtonPriority}
        className={cls(classes.root, className, {
          [classes.wired]: wired,
          [classes.noWrap]: noWrap,
        })}
        hoverStyle={HoverStyles.underline}
        {...rest}
      >
        {loading ? <Spinner className={classes.spinner} size={20} /> : children}
      </TextButtonTPA>
    );
  },
) as OverridableComponent<IProps, typeof TextButtonTPA>;

TextButton.displayName = 'wui/TextButton';
