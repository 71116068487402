import type { CustomCssVarsFn } from '@wix/yoshi-flow-editor';

import * as wui from 'settings/wui';
import * as feed from 'settings/feed';
import { getFeedCssVars } from 'settings/feed/vars';
import * as groups from 'settings/groups';
import { getGroupsCssVars } from 'settings/groups/vars';
import { styles } from 'settings/common';

export default {
  ...styles,
  ...wui.styles,
  ...feed.styles,
  ...groups.styles,
};

// noinspection JSUnusedGlobalSymbols
export const customCssVars: CustomCssVarsFn = (params) => ({
  ...getGroupsCssVars(params),
  ...getFeedCssVars(params),
});
