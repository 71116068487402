import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from 'Groups/stylesParams';
import { EImageRatio } from 'settings/constants';

import type { IGroup } from 'store/groups/types';

import { Box } from 'wui/Box';
import { Skeleton } from 'wui/Skeleton';
import { AspectRatio } from 'wui/AspectRatio';
import { ListItemIcon, type IListItemIconProps } from 'wui/ListItemIcon';

import { GroupImage } from 'common/components/GroupImage';

import classes from './GroupListItem.scss';

interface IGroupListItemImageProps extends Partial<IListItemIconProps> {
  group: IGroup;
}

export function GroupListItemImage(props: IGroupListItemImageProps) {
  const { group, className, ...rest } = props;
  const { isMobile } = useEnvironment();

  const styles = useStyles();

  const aspectRatio = styles.get(stylesParams.imageRatio) as EImageRatio;
  const isSquare = aspectRatio === EImageRatio.Square;

  const width = isMobile ? 40 : isSquare ? 72 : 105;

  return (
    <ListItemIcon className={cls(classes.image, className)} {...rest}>
      <Box width={width}>
        <GroupImage
          width={width}
          resize="cover"
          aspectRatio={aspectRatio}
          image={group.coverImage?.image}
        />
      </Box>
    </ListItemIcon>
  );
}

GroupListItemImage.displayName = 'GroupListItemImage';

export function GroupListItemImageSkeleton() {
  const { isMobile } = useEnvironment();
  const styles = useStyles();

  const aspectRatio = styles.get(stylesParams.imageRatio) as EImageRatio;
  const isSquare = aspectRatio === EImageRatio.Square;

  const width = isMobile ? 40 : isSquare ? 72 : 105;

  return (
    <ListItemIcon className={classes.image}>
      <AspectRatio width={width} aspectRatio={aspectRatio}>
        <Skeleton variant="rect" width="100%" height="100%" />
      </AspectRatio>
    </ListItemIcon>
  );
}
GroupListItemImageSkeleton.displayName = 'GroupListItemImageSkeleton';
