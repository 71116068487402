import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import classes from './ListItemButton.scss';

interface IListItemButtonProps extends TPAComponentProps {
  disabled?: boolean;
  disablePadding?: boolean;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export function ListItemButton(props: IListItemButtonProps) {
  const { children, className, disabled, disablePadding, ...rest } = props;

  const { isMobile } = useEnvironment();

  return (
    <div
      {...rest}
      role="button"
      tabIndex={disabled ? -1 : 0}
      onKeyDown={handleKeyDown}
      data-disabled={disabled}
      className={cls(classes.root, className, {
        [classes.mobile]: isMobile,
        [classes.disabled]: disabled,
        [classes.disablePadding]: disablePadding,
      })}
    >
      {children}
    </div>
  );

  function handleKeyDown(event: React.KeyboardEvent<HTMLElement>) {
    if (event.target !== event.currentTarget) {
      return;
    }

    if ((event.key === 'Enter' || event.key === ' ') && !disabled) {
      props.onClick?.(event as unknown as React.MouseEvent<HTMLElement>);
    }
  }
}

ListItemButton.displayName = 'wui/ListItemButton';
