import React from 'react';
import { useSelector } from 'react-redux';

import { useEnvironment } from '@wix/yoshi-flow-editor';

import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { EGroupListSort, ELayoutType } from 'settings/constants';
import { GroupsSelectorType } from 'settings/groups-list-widget/settings';
import settingsParams from 'GroupsListWidget/settingsParams';
import stylesParams from 'GroupsListWidget/stylesParams';
import { Typography } from 'wui/Typography';
import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { Hide } from 'wui/Hide';

import { useController } from 'common/context/controller';
import { useDidUpdate } from 'common/hooks';

import { selectGroups } from 'store/selectors';

import { EGroupPartition } from '@wix/social-groups-serverless/dist/groups/types';

import { ListLayout } from './ListLayout';
import { GridLayout } from './GridLayout';
import { SideBySideLayout } from './SideBySideLayout';

import { LayoutProps } from './types';
import classes from '../styles.scss';

type LayoutRootProps = {};

export const LayoutRoot: React.FC<LayoutRootProps> = () => {
  const settings = useSettings();
  const styles = useStyles();

  const groupsSelector = settings.get(settingsParams.groupsSelectorType);
  const groupIdsToShow = settings.get(settingsParams.groupIdsToShow);

  const { isMobile } = useEnvironment();

  const { groups$ } = useController();
  const state = useSelector(selectGroups(EGroupPartition.ALL));

  const limit = 6;
  const layout = settings.get(settingsParams.groupListLayout);
  const sortBy = settings.get(settingsParams.sortBy) as EGroupListSort;
  const { htmlTag: htmlSubtitleTag } = styles.get(
    stylesParams.listWidgetTitleFont,
  );

  const layoutProps: LayoutProps = {
    ...state,
    onRetry: handleRetry,
    onLoadMore: handleLoadMore,
    batchLength:
      groupsSelector === GroupsSelectorType.ALL
        ? limit
        : Math.min(limit, getGroupIdsToShow().length),
  };

  useDidUpdate(() => {
    groups$.query({
      limit,
      sort: sortBy,
      namespace: EGroupPartition.ALL,
      partition: EGroupPartition.ALL,
      groupIds: getGroupIdsToShow(),
    });
  }, [limit, sortBy, groupIdsToShow, groupsSelector]);

  return (
    <Box direction="vertical" gap={isMobile ? '24px' : '48px'}>
      <Typography
        as={htmlSubtitleTag as React.ElementType}
        variant="h2-20"
        className={classes.title}
      >
        {settings.get(settingsParams.groupListTitle)}
      </Typography>

      <Show if={layout === ELayoutType.Grid}>
        <GridLayout {...layoutProps} />
      </Show>

      <Show if={layout === ELayoutType.ListWidget}>
        <ListLayout {...layoutProps} />
      </Show>

      <Show if={layout === ELayoutType.SideBySide}>
        <Show if={isMobile}>
          <GridLayout {...layoutProps} />
        </Show>

        <Hide if={isMobile}>
          <SideBySideLayout {...layoutProps} />
        </Hide>
      </Show>
    </Box>
  );

  function handleRetry() {
    groups$.query({
      limit,
      offset: 0,
      namespace: EGroupPartition.ALL,
      partition: EGroupPartition.ALL,
      sort: sortBy,
      groupIds: getGroupIdsToShow(),
    });
  }

  function handleLoadMore() {
    groups$.query({
      limit,
      offset: state.groups.length,
      namespace: EGroupPartition.ALL,
      partition: EGroupPartition.ALL,
      sort: sortBy,
      groupIds: getGroupIdsToShow(),
    });
  }

  function getGroupIdsToShow() {
    switch (groupsSelector) {
      case GroupsSelectorType.ALL:
        return undefined;
      case GroupsSelectorType.CUSTOM:
        try {
          return JSON.parse(settings.get(settingsParams.groupIdsToShow));
        } catch (e: any) {
          return [];
        }
    }
  }
};

LayoutRoot.displayName = 'LayoutRoot';
