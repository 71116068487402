import React from 'react';
import cls from 'classnames';

import { ButtonGroup } from '../ButtonGroup';

import { classes as stClasses } from './ListItemAction.st.css';

interface IListItemActionProps
  extends React.ComponentProps<typeof ButtonGroup> {}

export function ListItemAction(props: IListItemActionProps) {
  return (
    <ButtonGroup
      orientation="horizontal"
      className={cls(stClasses.root, props.className)}
    >
      {props.children}
    </ButtonGroup>
  );
}

ListItemAction.displayName = 'wui/ListItemAction';
