import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { UISref } from '@wix/tpa-router/react';
import { TextButton } from 'wui/TextButton';

import classes from './GroupInfo.scss';

type PendingMembersCountProps = {
  count: number;
  dataHook?: string;
  withSeparator?: boolean;
  params: Record<string, any>;
};

export const PendingMembersCount = ({
  count,
  dataHook,
  params,
  withSeparator = true,
}: PendingMembersCountProps) => {
  const { t } = useTranslation();
  return (
    <>
      {withSeparator && <span className={classes.separator}>&middot;</span>}

      <UISref state="group.members" params={params}>
        <TextButton as="a" data-hook={dataHook}>
          <span data-hook="pending-members-count">
            {t('groups-web.group-list.pending-members.count_icu', {
              count,
            })}
          </span>
        </TextButton>
      </UISref>
    </>
  );
};

PendingMembersCount.displayName = 'PendingMembersCount';
