import React from 'react';

import { Image } from '@wix/ambassador-social-groups-v2-group/types';

import { Media } from 'wui/Media';

import { EImageRatio } from 'settings/constants';

interface GroupImageProps
  extends Omit<React.ComponentProps<typeof Media>, 'src' | 'aspectRatio'> {
  image?: Image;
  aspectRatio?: EImageRatio;
}
export function GroupImage({ image, aspectRatio, ...rest }: GroupImageProps) {
  const { width, height } = getFallbackDimensions(toAspectRatio(aspectRatio));
  return (
    <Media
      isInFirstFold
      src={(image?.mediaId as string) || (image?.fileUrl as string)}
      sourceHeight={image?.height || height}
      sourceWidth={image?.width || width}
      height={image?.height || height}
      width={image?.width || width}
      aspectRatio={toAspectRatio(aspectRatio)}
      {...rest}
    />
  );

  function toAspectRatio(ratio?: EImageRatio) {
    if (ratio === undefined) {
      return;
    }

    return ratio === EImageRatio.Rectangle ? 'cinema' : 'square';
  }

  function getFallbackDimensions(
    aspectRatio: 'square' | 'cinema' | 'landscape' = 'cinema',
  ) {
    const fallbackDimensionsByAspectRatio = {
      square: { width: 400, height: 400 }, // 1:1
      cinema: { width: 640, height: 360 }, // 16:9
      landscape: { width: 640, height: 480 }, // 4:3
    };
    return fallbackDimensionsByAspectRatio[aspectRatio];
  }
}

GroupImage.displayName = 'GroupImage';
