import React from 'react';

import { GroupGrid } from 'Groups/Widget/Layout/GridLayout/GroupGrid';
import { EmptyGroupList } from '../../EmptyGroupList';

import { LayoutProps } from './types';

export const GridLayout: React.FC<LayoutProps> = (props) => {
  return <GroupGrid {...props} emptyState={<EmptyGroupList />} />;
};

GridLayout.displayName = 'GridLayout';
