import React from 'react';

import {
  ErrorMonitorBoundary,
  useEnvironment,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from 'Groups/stylesParams';

import type { PagingMetadata } from '@wix/ambassador-social-groups-v2-group/types';

import { IGroup, IGroupListOperationStatus } from 'store/groups';

import { Box } from 'wui/Box';
import { Button } from 'wui/Button';
import { Show } from 'wui/Show';
import { Fade } from 'wui/Fade';
import { Grid } from 'wix-ui-tpa/cssVars';
import { ErrorState } from 'wui/ErrorState';

import { GroupGridItem, GroupGridItemSkeleton } from './GroupGridItem';

import classes from './GroupGrid.scss';

interface IGroupGridProps {
  groups: IGroup[];
  meta: PagingMetadata;
  status: IGroupListOperationStatus;

  emptyState: React.ReactElement;

  batchLength?: number;

  onRetry(): void;
  onLoadMore?(): void;
}

function getPlaceholders(count: number) {
  return new Array(count).fill(0).map((_, index) => (
    <Fade key={index}>
      <GroupGridItemSkeleton key={index} />
    </Fade>
  ));
}

export function GroupGrid(props: IGroupGridProps) {
  const { groups, meta, status, batchLength = 6 } = props;

  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  const styles = useStyles();

  const hasMore = groups.length < (meta.total as number);
  const maxColumns = isMobile ? 1 : 3;

  if (status.fetch?.loading) {
    return (
      <Grid
        id="group-list-grid"
        maxColumns={maxColumns}
        data-hook="group-grid"
        data-status="loading"
        columnGap={styles.get(stylesParams.groupCardGridSpacing)}
        rowGap={styles.get(stylesParams.groupCardGridSpacing)}
        className={classes.root}
      >
        {getPlaceholders(batchLength)}
      </Grid>
    );
  }

  if (status.fetch?.error) {
    return (
      <ErrorState
        onRetry={props.onRetry}
        data-hook="group-grid"
        data-status="fetch-error"
        title={t('groups-web.error-state.title')}
        subtitle={t('groups-web.toast.error.groups.query')}
        actionLabel={t('groups-web.error-state.retry.label')}
      />
    );
  }

  if (!groups.length) {
    return props.emptyState;
  }

  return (
    <ErrorMonitorBoundary
      fallback={
        <ErrorState
          data-hook="group-grid"
          data-status="unexpected-error"
          title={t('groups-web.error-state.title')}
          subtitle={t('groups-web.error-state.subtitle')}
          actionLabel={t('groups-web.error-state.retry.label')}
        />
      }
    >
      <Grid
        id="group-list-grid"
        data-hook="group-grid"
        data-status="success"
        maxColumns={maxColumns}
        columnGap={styles.get(stylesParams.groupCardGridSpacing)}
        rowGap={styles.get(stylesParams.groupCardGridSpacing)}
        className={classes.root}
      >
        {groups.map((group) => (
          <Grid.Item key={group.id}>
            <GroupGridItem data-hook="group-grid-item" group={group} />
          </Grid.Item>
        ))}
        <Show if={status.fetchMore?.loading}>
          {getPlaceholders(batchLength)}
        </Show>
      </Grid>
      <Show if={hasMore && !!props.onLoadMore}>
        <Box align="center" marginTop={isMobile ? 'SP3' : 'SP4'}>
          <Button outlined onClick={props.onLoadMore} data-hook="load-more">
            {t('groups-web.showMore')}
          </Button>
        </Box>
      </Show>
    </ErrorMonitorBoundary>
  );
}

GroupGrid.displayName = 'GroupGrid';
