import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Paper } from 'wui/Paper';
import { Box } from 'wui/Box';

import { GroupList } from 'Groups/Widget/Layout/ListLayout/GroupList';
import { EmptyGroupList } from '../../EmptyGroupList';

import { LayoutProps } from './types';

export const ListLayout: React.FC<LayoutProps> = (props) => {
  const { isMobile } = useEnvironment();

  return (
    <Paper>
      <Box direction="vertical" as="section" padding={isMobile ? 'SP0' : 'SP5'}>
        <GroupList {...props} emptyState={<EmptyGroupList />} />
      </Box>
    </Paper>
  );
};

ListLayout.displayName = 'ListLayout';
