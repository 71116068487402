import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';

import stylesParams from 'Groups/stylesParams';
import { EImageRatio } from 'settings/constants';

import { AspectRatio } from 'wui/AspectRatio';
import { Card } from 'wui/Card';
import { CardActions } from 'wui/CardActions';
import { CardContent } from 'wui/CardContent';
import { Skeleton } from 'wui/Skeleton';
import { Button } from 'wui/Button';

import classes from './GroupGridItem.scss';

export function GroupGridItemSkeleton() {
  const styles = useStyles();
  const { isMobile } = useEnvironment();

  return (
    <Card
      gap="SP0"
      padding="SP0"
      sideBorders={!isMobile}
      className={classes.root}
    >
      <AspectRatio
        className={classes.image}
        aspectRatio={styles.get(stylesParams.imageRatio) as EImageRatio}
      >
        <Skeleton variant="rect" width="100%" height="100%" />
      </AspectRatio>

      <CardContent disableSideGutters className={classes.content}>
        <Skeleton
          className={cls(classes.title, { [classes.mobile]: isMobile })}
          variant="text"
        />
        <Skeleton
          className={cls(classes.info, { [classes.mobile]: isMobile })}
          variant="text"
          width="50%"
        />
      </CardContent>

      <CardActions
        paddingTop="SP1"
        disableSideGutters
        className={cls(classes.actions, classes.hidden)}
      >
        <Button>&nbsp;</Button>
      </CardActions>
    </Card>
  );
}

GroupGridItemSkeleton.displayName = 'GroupGridItemSkeleton';
