import { Direction } from 'wui/Box/types';
import { EImageLayout } from 'settings/constants';

export function getDirection(
  imageLayout: EImageLayout,
  even?: boolean,
): Direction {
  let direction: Direction =
    imageLayout === EImageLayout.LTR ? 'horizontal' : 'horizontal-reverse';
  if (imageLayout === EImageLayout.Mixed && even) {
    direction = 'horizontal';
  }
  return direction;
}
