import React from 'react';
import { TransitionGroup } from 'react-transition-group';

import {
  ErrorMonitorBoundary,
  useEnvironment,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import { List } from 'wui/List';
import { Fade } from 'wui/Fade';
import { ErrorState } from 'wui/ErrorState';
import { Show } from 'wui/Show';
import { Box } from 'wui/Box';
import { Button } from 'wui/Button';

import { LayoutProps } from '../types';
import { EmptyGroupList } from '../../../EmptyGroupList';

import { GroupStripeItem, GroupStripeItemSkeleton } from './GroupStripeItem';

import classes from './SideBySideLayout.scss';

function getPlaceholders(count: number) {
  return new Array(count).fill(0).map((_, index) => (
    <Fade key={index}>
      <GroupStripeItemSkeleton key={index} even={index % 2 === 0} />
    </Fade>
  ));
}

export const SideBySideLayout: React.FC<LayoutProps> = (props) => {
  const { groups, status, meta } = props;
  const hasMore = groups.length < (meta.total as number);

  const { isMobile } = useEnvironment();

  const { t } = useTranslation();

  if (status.fetch?.loading) {
    return (
      <List className={classes.gap}>{getPlaceholders(props.batchLength)}</List>
    );
  }

  if (status.fetch?.error) {
    return (
      <ErrorState
        onRetry={props.onRetry}
        title={t('groups-web.error-state.title')}
        subtitle={t('groups-web.toast.error.groups.query')}
        actionLabel={t('groups-web.error-state.retry.label')}
      />
    );
  }

  if (!groups.length) {
    return <EmptyGroupList />;
  }

  return (
    <ErrorMonitorBoundary
      fallback={
        <ErrorState
          title={t('groups-web.error-state.title')}
          subtitle={t('groups-web.error-state.subtitle')}
          actionLabel={t('groups-web.error-state.retry.label')}
        />
      }
    >
      <List disablePadding className={classes.gap}>
        <TransitionGroup component={null}>
          {groups.map((group, index) => (
            <GroupStripeItem
              group={group}
              key={group.id}
              even={index % 2 === 0}
            />
          ))}
        </TransitionGroup>
        <Show if={status.fetchMore?.loading}>
          {getPlaceholders(props.batchLength as number)}
        </Show>
      </List>
      <Show if={hasMore && !!props.onLoadMore}>
        <Box align="center" marginTop={isMobile ? 'SP3' : 'SP4'}>
          <Button outlined onClick={props.onLoadMore}>
            {t('groups-web.showMore')}
          </Button>
        </Box>
      </Show>
    </ErrorMonitorBoundary>
  );
};

SideBySideLayout.displayName = 'SideBySideLayout';
