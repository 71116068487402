import React from 'react';
import cls from 'classnames';

import { UISref } from '@wix/tpa-router/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { TextButton } from 'wui/TextButton';
import type { TPAComponentProps } from 'wui/types';

import { groupsLivesiteClick } from '@wix/bi-logger-groups/v2';

import type { IGroup } from 'store/groups';

import { ListItem } from 'wui/ListItem';
import { ButtonSize } from 'wui/Button';
import { ListItemText } from 'wui/ListItemText';
import { ListItemAction } from 'wui/ListItemAction';

import { GroupInfo } from 'common/components/GroupInfo';
import { GroupMembershipButton } from 'common/components/GroupMembership';
import { useBiParams } from 'common/hooks/useBiParams';

import { GroupListItemImage } from './GroupListItemImage';

import classes from './GroupListItem.scss';

interface IGroupListItemProps extends TPAComponentProps {
  group: IGroup;
}

export function SuggestedGroupListItem(props: IGroupListItemProps) {
  const { group, className, ...rest } = props;

  const { isMobile } = useEnvironment();
  const biParams = useBiParams();

  return (
    <ListItem
      {...rest}
      disablePadding
      className={cls(classes.root, className, { [classes.mobile]: isMobile })}
    >
      <UISref
        state="group"
        params={{ slug: group.slug }}
        bi={groupsLivesiteClick({
          screen_name: biParams.groupsScreenWithTab(),
          button_name: 'click_on_group',
        })}
      >
        <a tabIndex={-1} className={classes.imageLink}>
          <GroupListItemImage group={group} />
        </a>
      </UISref>

      <ListItemText
        title={
          <UISref
            state="group"
            params={{ slug: group.slug }}
            bi={groupsLivesiteClick({
              screen_name: biParams.groupsScreenWithTab(),
              button_name: 'click_on_group',
            })}
          >
            <TextButton as="a" variant="secondary" data-hook="group-name">
              {group.name}
            </TextButton>
          </UISref>
        }
        subtitle={
          <GroupInfo
            wired
            data-hook="group-info"
            groupId={group.id as string}
          />
        }
        titleProps={{
          noWrap: true,
          className: cls(classes.title, { [classes.mobile]: isMobile }),
        }}
        subtitleProps={{ noWrap: true }}
      />

      <ListItemAction>
        <GroupMembershipButton
          redirect
          upgrade={!isMobile}
          className={classes.action}
          size={isMobile ? ButtonSize.tiny : undefined}
          groupId={group.id as string}
          bi={groupsLivesiteClick({
            group_id: group.id as string,
            screen_name: biParams.groupsScreenWithTab(),
            button_name: 'join',
          })}
        />
      </ListItemAction>
    </ListItem>
  );
}

SuggestedGroupListItem.displayName = 'SuggestedGroupListItem';
