import React from 'react';
import cls from 'classnames';

import type { TPAComponentProps } from '../types';
import { Typography } from '../Typography';

import classes from './ListItemText.scss';

interface IListItemTextProps extends TPAComponentProps {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;

  titleProps?: React.ComponentProps<typeof Typography>;
  subtitleProps?: React.ComponentProps<typeof Typography>;
}

export function ListItemText(props: IListItemTextProps) {
  const {
    titleProps,
    subtitleProps,
    className,
    title: _,
    subtitle: __,
    ...rest
  } = props;

  let title: React.ReactElement;
  let subtitle: React.ReactElement;

  if (props.title && (props.title as React.ReactElement).type !== Typography) {
    title = (
      <Typography
        variant="p2-16"
        as={typeof props.title === 'string' ? 'p' : 'div'}
        {...titleProps}
      >
        {props.title}
      </Typography>
    );
  } else {
    title = props.title as React.ReactElement;
  }

  if (
    props.subtitle &&
    (props.subtitle as React.ReactElement).type !== Typography
  ) {
    subtitle = (
      <Typography
        noWrap
        secondary
        variant="p2-14"
        as={typeof props.subtitle === 'string' ? 'p' : 'div'}
        {...subtitleProps}
      >
        {props.subtitle}
      </Typography>
    );
  } else {
    subtitle = props.subtitle as React.ReactElement;
  }
  return (
    <div className={cls(classes.root, className)} {...rest}>
      {title && <div data-hook="list-item-text-title">{title}</div>}
      {subtitle && <div data-hook="list-item-text-subtitle">{subtitle}</div>}
    </div>
  );
}

ListItemText.displayName = 'wui/ListItemText';
